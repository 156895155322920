<template>
  <header>
    <div class="main-section pb-5">
      <div class="container">
        <div class="row overflow-hidden">
          <div
            class="col-sm-12 col-md-6 d-flex justify-content-center align-items-center order-2 order-md-1 text-center text-sm-end"
          >
            <div class="main-text">
              <h2 class="display-3 fw-bold py-3">شركة الصباغ®</h2>
              <h4 class="display-6">للصناعات الكهربائية</h4>
              <p class="lead">
                هي إحدى الشركات الرائدة في الصناعات الهندسية بالشرق الأوسط والتي
                لها دور في إجراء البحوث و التجارب على طرق الإنارة الحديثة وتم
                تطوير العديد من الأشكال الهندسية للإنارة، وهي رائدة بترشيد
                الاستهلاك و التوفير الفعلي للطاقة الكهربائية، حيث أعطت الإضاءة
                المرتفعة بإستهلاك منخفض
              </p>
              <p>تأسست سنة 1997</p>
              <div>
                <button
                  class="btn btn-dark btn-lg ms-2"
                  @click="$router.push(`/products`)"
                >
                  منتجاتنا
                </button>
                <a class="btn btn-light btn-lg" href="tel:+20 102 696 4584"
                  >اتصل بنا</a
                >
              </div>
            </div>
          </div>
          <div
            class="image col-sm-12 col-md-6 mt-4 order-1 order-md-2 overflow-hidden d-flex align-items-center"
          >
            <img
              src="@/assets/images/sabbagh-site.png"
              alt="light"
              class="img-fluid"
            />
            <img
              src="@/assets/images/sabbagh-site.png"
              alt="light"
              class="img-fluid"
            />
            <img
              src="@/assets/images/sabbagh-site.png"
              alt="light"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- start product sectoin -->

  <div class="products py-5">
    <div class="container">
      <h2 class="text-center display-2 py-5"><u>منتجاتنا </u></h2>
      <div class="row" dir="rtl">
        <ProductCard
          v-for="product in randomProducts"
          :key="product.id"
          :productData="product"
        />
      </div>
      <div class="product-btn text-center my-3">
        <p class="fs-2">لمشاهدة جميع منتجاتنا</p>
        <button class="btn btn-dark btn-lg" @click="$router.push(`/products`)">
          انقر للمزيد ...
        </button>
      </div>
    </div>
  </div>

  <!-- end product sectoin -->
  <!-- start services  -->

  <div class="services text-center mb-5 pb-5 fs-2">
    <div class="container">
      <h2 class="text-center display-2 py-5"><u>مميزات الشركة </u></h2>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 mt-4">
          <div class="py-5 serv-color">
            <!-- <i class="icon-big fas fa-globe"></i>
            <p>صناعة مصرية</p>
            <p>بمواصفات عالمية</p> -->
            <img
              src="../assets/images/button/Asset1.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mt-4">
          <div class="py-5 serv-color">
            <img
              src="../assets/images/button/Asset2.png"
              class="img-fluid"
              alt=""
            />
            <!-- <i class="icon-big fas fa-headset"></i>
            <p>ضمان حقيقي</p>
            <p>لمدة عام كامل</p> -->
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mt-4 m-auto">
          <div class="py-5 serv-color">
            <img
              src="../assets/images/button/Asset3.png"
              class="img-fluid"
              alt=""
            />
            <!-- <i class="icon-big fas fa-truck"></i>
            <p>قطع غيار متوفرة</p>
            <p>لجميع المنتجات</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end services -->

  <!-- start contact us  -->

  <section class="contact-us w-100 shadow">
    <div
      class="container h-100 py-5 text-light text-end fs-1 d-flex flex-column justify-content-center align-items-end"
    >
      <p>الإضاءة كما يجب أن تكون</p>
      <a class="btn btn-light btn-lg" href="tel:+20 102 696 4584">اتصل بنا</a>
    </div>
  </section>
  <!-- end contact us  -->

  <!-- start branches  -->

  <div class="our-branches">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6 my-auto">
          <h2 class="display-4 my-5">منافذ البيع :</h2>
          <ul class="h3 list-unstyled">
            <li>
              العاشر من رمضان:
              <a
                dir="ltr"
                class="fw-bold d-block d-lg-inline"
                href="tel:+20 102 696 4618"
                >+20 102 696 4618</a
              >
            </li>
            <li>
              الاسكندرية:
              <a
                dir="ltr"
                class="fw-bold d-block d-lg-inline"
                href="tel:+20 102 696 3987"
                >+20 102 696 3987</a
              >
            </li>
            <li>
              القاهرة:
              <a
                dir="ltr"
                class="fw-bold d-block d-lg-inline"
                href="tel:+20 102 856 6822"
                >+20 102 856 6822</a
              >
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-6">
          <img
            src="@/assets/images/global.svg"
            class="w-100 pt-3"
            alt="global"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- end branches -->
</template>

<script>
import ProductCard from "@/components/ProductCard";
export default {
  components: {
    ProductCard
  },
  // mounted() {
  //   const arr = this.$store.getters.getData;
  //   console.log(arr.sort(() => Math.random() - Math.random()).slice(0, 3));
  //   console.log(arr.filter((a) => a.family == "tree" && a.type == "s"));
  //   // array.sort(() => Math.random() - Math.random()).slice(0, n)
  // },
  computed: {
    randomProducts() {
      const arr = this.$store.getters.getData;
      return arr.sort(() => Math.random() - Math.random()).slice(0, 3);
    }
  }
};
</script>
